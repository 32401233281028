import Axios from "axios";
import { useHistory } from "react-router";
import auth from "./auth";
// export const endpoint = "https://api.wonderppl.co.kr/api";
export const endpoint = "https://api.wonderppl.fifteenh.io/api";
export const api = Axios.create({
  baseURL: endpoint,
  headers: {
    Authorization: `Bearer ${auth.getToken()}`,
  },
});

api.interceptors.response.use(
  (response) => response,
  async (error) => {
    const history = useHistory();

    if (
      error.response &&
      error.response.status === 401 &&
      !error.config._retry
    ) {
      try {
        if (auth.getRefreshToken() !== "undefined") {
          const {
            data: {
              data: { access_token, refresh_token },
            },
          } = await api.post("/refresh/token", {
            refresh_token: auth.getRefreshToken(),
          });

          if (access_token && refresh_token) {
            auth.setToken(access_token);
            auth.setRefreshToken(refresh_token);
            api.defaults.headers.common["Authorization"] = access_token;
            return api.request(error.config);
          } else {
            auth.clearAppStorage();
            history.push("/");
          }
        } else {
          return null;
        }
      } catch (err) {
        return null;
      }
    } else if (error.response.status === 401) {
      if (auth.getToken()) {
        auth.clear("token");
      }
    }

    return Promise.reject(error);
  }
);
